import { NgModule } from '@angular/core';

import { BaoyingRoutingModule } from './baoying-routing.module';

import { SharedModule } from '@shared/lib/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { I18nInfoConfig, MarketActiveStatus, I18nOrganization, I18nHonorCookie } from '@usana/ux/markets';
import { BaoyingAppComponent } from './components/app-root/baoying-app.component';
import { Locales, Markets } from './baoying.providers';
import { BaoyingTranslationService } from './services/baoying-translation.service';
import { TranslationService } from '@usana/ux/translations';
import { SharedProviders } from '@shared/lib/shared.providers';
import { environment } from '../environments/environment';
import { FooterCnComponent } from './components/footer-cn/footer-cn.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { PageNotFoundModule } from '@shared/lib/modules/page-not-found/page-not-found.module';
import { OnlineConsultationComponent } from './components/online-consultation/online-consultation.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BaoyingFontAwesomeModule } from './baoying-font-awesome.module';
import { URL_SITE_DOMAIN } from '@usana/ux/common/url';

@NgModule({
  declarations: [
    BaoyingAppComponent,
    FooterCnComponent,
    NewsListComponent,
    OnlineConsultationComponent,
    PaginationComponent,
  ],
  imports: [BaoyingRoutingModule, BrowserModule, SharedModule, PageNotFoundModule, BaoyingFontAwesomeModule],
  providers: [
    new SharedProviders(
      environment,
      Locales,
      Markets,
      'zhs-CN',
      () =>
        new I18nInfoConfig(
          false,
          false,
          true,
          false,
          true,
          false,
          false,
          false,
          MarketActiveStatus.ACTIVE,
          I18nOrganization.BAOYING,
          I18nHonorCookie.ALWAYS
        )
    ).providers.concat([
      { provide: TranslationService, useClass: BaoyingTranslationService },
      { provide: URL_SITE_DOMAIN, useValue: environment.siteDomain },
    ]),
  ],
  bootstrap: [BaoyingAppComponent],
})
export class BaoyingModule {}
