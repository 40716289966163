<div *ngIf="dataIsLoaded && marketIn('zhs-CN')">
  <div class="logo-container">
    <img class="logo-usana" src="assets/zh-CN/images/logo_usana_white.svg" alt="USANA Logo" />
    <img class="logo-baoying" src="assets/zh-CN/images/logo_baoying_white.png" alt="Baoying Logo" />
  </div>
  <div class="container">
    <div class="row footer-links">
      <div class="footer-link-column col-md-3" *ngFor="let item of data.items; index as i">
        <div class="column-title" (click)="isCollapsed[i] = !isCollapsed[i]">
          {{ item.name }} <fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
        </div>
        <div [ngbCollapse]="!isCollapsed[i]">
          <div *ngFor="let child of item.children" class="children">
            <a href="{{ child.url }}" target="{{ child.target }}" [innerHTML]="child.name"></a>
          </div>
        </div>
      </div>
      <div class="footer-link-column col-md-3">
        <div class="column-title">{{ data.followText }}</div>
        <a class="icon-link-item" (click)="isShow = !isShow" *ngFor="let meanInfo of data.meanInfos">
          <fa-icon [icon]="['fab', meanInfo.iconName]"></fa-icon>

          <div
            class="link-menu-item"
            *ngIf="isShow"
            [ngStyle]="{ 'margin-left': meanInfo?.hasMultipleImages === 'true' ? '-136px' : '-64px' }"
          >
            <span class="arrow"></span>
            <img
              [src]="meanInfo.image"
              [ngClass]="meanInfo?.hasMultipleImages === 'true' ? 'multiple_images' : 'one_image'"
              alt="beian"
            />
            <p [innerHTML]="meanInfo.name" *ngIf="meanInfo.name"></p>
          </div>
        </a>
      </div>
    </div>
    <div>
      <a
        class="optanon-toggle-display cookie-settings-policy"
        [innerHTML]="data.cookiesettings"
        data-automation="cookiesettings"
      ></a>
    </div>
    <!-- Copyright information -->
    <div class="disclaimer">
      <p class="copyright-info" [innerHTML]="data.disclaimer1"></p>
      <div class="copyright-link">
        <a href="{{ copyright.linkUrl }}" *ngFor="let copyright of data.copyrights; index as i">
          <img class="beian" [src]="copyright.image" alt="" />{{ copyright.name }}
        </a>
      </div>
    </div>
  </div>
</div>
