import { Injectable } from '@angular/core';
import { UsanaMarketService } from '@usana/ux/markets';
import { HttpClient } from '@angular/common/http';
import { SharedDotcomConstants } from '@shared/lib/shared-dotcom.constants';
import { TranslationWrapper } from '../model/translation-wrapper';

// NOTE: more or less copied from old translation.service.ts
@Injectable()
export class BaoyingTranslationService {
  promiseMap: { [key: string]: Promise<any> };
  pipeMap: { [key: string]: string };
  locale: string;
  private readonly headers = {
    'Content-Type': 'application/json',
  };

  private readonly releaseVersion: string = '___helm_pod_hostname___';
  private readonly releaseVersionKey: string = 'dc-releaseVersion';
  private readonly translationKeyPrefix: string = 'dc-translation-';

  constructor(private http: HttpClient, private usanaMarketService: UsanaMarketService) {
    this.updateVersion();
    this.locale = null;
    this.promiseMap = {};
    this.pipeMap = {};
  }

  isDebugMode(): boolean {
    return false;
  }

  toggleDebugMode(): void {
    // not supported
  }

  setMarketId(marketId: string): void {
    this.promiseMap = {};
    this.pipeMap = {};
    this.locale = this.usanaMarketService.unsafeGetI18nInfo().current.portalMarketId;
  }

  translate(pkg: string, tag: string): string | null {
    if (this.locale && this.pipeMap[tag] === undefined) {
      this.pipeMap[tag] = '';
      this.translateTags([tag], this.toStorageKey(tag), true).then((wrapper: TranslationWrapper) => {
        this.pipeMap[tag] = wrapper.value;
      });
    }

    return this.pipeMap[tag];
  }

  translateAsPromise(pkg: string, tag: string): Promise<string> {
    return this.translateMultiAsPromise(pkg, [tag]).then((ret: { [tag: string]: string }) => ret[tag]);
  }

  translateMultiAsPromise(pkg: string, tags: string[]): Promise<{ [tag: string]: string }> {
    const filename = this.getFilenameFromTag(tags[0]);

    return this.translateTags(tags, filename, false).then((wrapper: TranslationWrapper) => wrapper.value);
  }

  private translateTags(origTags: string[], cacheKey: string, asSingle: boolean): Promise<TranslationWrapper> {
    return new Promise<TranslationWrapper>((resolve, reject) => {
      const storedContent: TranslationWrapper = this.getStoredContent(cacheKey);
      if (!!storedContent) {
        resolve(storedContent);
      } else {
        const tags = origTags.map((ot) => SharedDotcomConstants.TRANSLATION_NAMESPACE + '.' + ot);

        if (!this.promiseMap[cacheKey]) {
          this.promiseMap[cacheKey] = this.http
            .post(`/mvc/translation/get?locale=${this.locale}`, tags, { headers: this.headers })
            .toPromise();
        }

        this.promiseMap[cacheKey].then((data) => {
          const wrapper: TranslationWrapper = {
            lastUpdated: new Date().getTime(),
            // eslint-disable-next-line
            value: this.mapData(data['translations'], asSingle, tags[0]),
            locale: data.requestLocale,
          };
          localStorage.setItem(cacheKey, JSON.stringify(wrapper));
          resolve(wrapper);
          delete this.promiseMap[cacheKey];
        });
      }
    });
  }

  private isNonExpired(wrapper: TranslationWrapper): boolean {
    const now = new Date().getTime();

    return (
      !!wrapper &&
      !!wrapper.lastUpdated &&
      now < wrapper.lastUpdated + SharedDotcomConstants.TRANSLATION_TTL &&
      !!wrapper.locale &&
      wrapper.locale === this.locale
    );
  }

  private toStorageKey(identifier: string) {
    return this.translationKeyPrefix + identifier;
  }

  private getStoredContent(key: string): TranslationWrapper {
    if (!!Storage) {
      const storedString = localStorage.getItem(key);

      if (!!storedString) {
        const wrapper: TranslationWrapper = JSON.parse(storedString);
        if (this.isNonExpired(wrapper)) {
          return wrapper;
        }
      }
    }

    return null;
  }

  private mapData(map, asSingle: boolean, singleTag: string): any {
    if (asSingle) {
      return this.correctTagValIfNeeded(singleTag, map[singleTag]);
    } else {
      const correctedMap = {};
      for (const key of Object.keys(map)) {
        correctedMap[key.substring(SharedDotcomConstants.TRANSLATION_NAMESPACE.length + 1)] =
          this.correctTagValIfNeeded(key, map[key]);
      }
      return correctedMap;
    }
  }

  private correctTagValIfNeeded(tag: string, val: string): string {
    // we have to recorrect missing translations val is same as tag since we are stripping the prefix.
    // this means the prefix needs to be stripped from both
    if (val === tag && val.indexOf(SharedDotcomConstants.TRANSLATION_NAMESPACE) === 0) {
      return val.substring(SharedDotcomConstants.TRANSLATION_NAMESPACE.length + 1);
    }
    return val;
  }

  private getFilenameFromTag(tag: string): string {
    return this.toStorageKey(tag.split('.')[0]);
  }

  private updateVersion(): void {
    if (!!Storage) {
      const version = localStorage.getItem(this.releaseVersionKey);
      if (version !== this.releaseVersion) {
        localStorage.setItem(this.releaseVersionKey, this.releaseVersion);
        // dump existing translations
        for (const key in localStorage) {
          if (key.indexOf(this.translationKeyPrefix) === 0) {
            localStorage.removeItem(key);
          }
        }
      }
    }
  }
}
