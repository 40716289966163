// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const local = {
  siteDomain: 'usanalocal.com',
};

export const environment = {
  production: false,
  host: `https://www.${local.siteDomain}`,
  cartService: `https://www.${local.siteDomain}/cart`,
  i18nService: `https://api.${local.siteDomain}/i18n`,
  gatewayUrl: `https://api.${local.siteDomain}/dotcom-gateway/`,
  siteDomain: local.siteDomain,
  loginRealm: 'usana',
  ssoEnabled: true,
  recaptchaEnabled: true,
  recaptchaV3SiteKey: '6Ldm_IkhAAAAAMpVYA6jxytxTaGUbyc3fC98Axd4',
};
