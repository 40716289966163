import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'u-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pageIndexes: number[] = [];
  @Input() currentPageIndex: number;
  @Input() maxItemSize: number;
  @Output() readonly currentPageIndexChange = new EventEmitter<number>();
  start: number;
  end: number;
  centralIndexesSize: number;

  ngOnInit(): void {
    this.start = this.pageIndexes[0];
    this.end = this.pageIndexes[this.pageIndexes.length - 1];
    this.centralIndexesSize = Math.floor(this.maxItemSize - 1) / 2;
  }

  onClickPageIndex(index: number) {
    this.currentPageIndexChange.emit(index);
  }

  onClickPrevious() {
    this.currentPageIndexChange.emit(this.currentPageIndex - 1);
  }

  onClickNext() {
    this.currentPageIndexChange.emit(this.currentPageIndex + 1);
  }
}
