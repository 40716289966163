<div *ngIf="dataIsLoaded">
  <ul class="list-group root-menu" [class.root-to-markets]="rootToMarkets" [class.back-to-root]="backToRoot">
    <li class="list-group-item logo-container">
      <ng-template #usanaLogo
        ><a href="/" data-automation="logo-mobile-home"
          ><img src="{{ 'assets/USANA_logo_black.svg' }}" class="w-100" alt="Home" /></a
      ></ng-template>
      <ng-container *ngIf="isBaoying(); else usanaLogo">
        <a href="/" data-automation="logo-mobile-home"
          ><img src="{{ 'assets/logo.png' }}" class="w-100" alt="Home"
        /></a>
      </ng-container>
    </li>
    <ng-container *ngFor="let item of data">
      <li class="list-group-item" [class.children]="item.children" *ngIf="hasInnerText(item.name)">
        <a
          [href]="[item.url]"
          (click)="handleLink($event, item.url, item.target)"
          attr.data-automation="{{ urlPathId(item.url) }}"
          target="{{ item.target }}"
          class="u-media"
        >
          <div class="media-body">{{ item.name }}</div>

          <fa-icon *ngIf="item.children" [icon]="['fal', 'angle-right']"></fa-icon>
        </a>
      </li>
    </ng-container>
    <li class="list-group-item selected-market" *ngIf="!isBaoying()">
      <button class="u-media align-items-center p-0" (click)="selectMarket()" data-automation="mobileSelectMarketButton">
        <div class="media-body flex-grow-1">
          <div><small class="muted" [innerHTML]="current_market_locale.select_text"></small></div>
          <div class="u-media">
            <span class="flag-icon flag-icon-{{ current_market.flag }}"></span>
            <div class="media-body" [innerHTML]="market_name"></div>
          </div>
        </div>

        <fa-icon [icon]="['fal', 'angle-right']"></fa-icon>
      </button>
    </li>
    <li class="list-group-item selected-market">
      <ng-container *ngIf="isLoggedIn() else login">
        <ng-container *ngIf="['A','D','EM'].includes(customerType); else showLogout">
          <a [href]="hubUrl" data-automation="hub-link" class="btn btn-default">
            <span [innerHTML]="'the-hub' | i18n:'usanadotcom'"></span>&nbsp;
          </a>
        </ng-container>
        <ng-template #showLogout>
          <a href (click)="logout()" data-automation="hub-logout" class="btn btn-default">
            <span [innerHTML]="'logout' | i18n:'usanadotcom'"></span>&nbsp;
          </a>
        </ng-template>
      </ng-container>
      <ng-template #login>
        <a
          href="{{ hubUrl }}"
          (click)="goToHubOrLogin($event)"
          attr.data-automation="{{ 'hub-' + urlPathId(hubUrl) }}"
          class="btn btn-default"
          ><span [innerHTML]="'login' | i18n : 'usanadotcom'"></span>&nbsp;</a
        >
      </ng-template>
    </li>
  </ul>

  <ul
    class="list-group market-list"
    [class.markets-to-languages]="marketsToLanguages"
    [class.back-to-markets]="backToMarkets"
  >
    <li class="list-group-item">
      <button class="u-media" (click)="backFromMarkets()">
        <fa-icon [icon]="['fal', 'angle-left']"></fa-icon>
        <div class="media-body">{{ current_market_locale.back_text }}</div>
      </button>
    </li>
    <li *ngFor="let item of markets" class="list-group-item">
      <button class="u-media" (click)="selectLanguages(item.name)">
        <span class="flag-icon flag-icon-{{ item.flag }} flex-shrink-0"></span>

        <div class="media-body flex-grow-1">{{ item.name }}</div>

        <fa-icon *ngIf="item.languages" [icon]="['fal', 'angle-right']"></fa-icon>
      </button>
    </li>
  </ul>

  <ul class="list-group language-list" #languagePickerMobile>
    <li class="list-group-item">
      <button class="u-media" (click)="backFromLanguages()">
        <fa-icon [icon]="['fal', 'angle-left']"></fa-icon>

        <div class="media-body">{{ current_market_locale.back_text }}</div>
      </button>
    </li>

    <li *ngFor="let item of selected_market.languages" class="list-group-item">
      <button class="u-media" (click)="setLocale(item.locale)">
        <div class="media-body">{{ item.language }}</div>
      </button>
    </li>
  </ul>
</div>
