<div class="news-list-container" *ngIf="dataIsLoaded">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#/zhs-CN/home" [innerHTML]="navData.navText1"></a></li>
    <li class="breadcrumb-item active" aria-current="page" [innerHTML]="navData.navText2"></li>
  </ol>

  <div class="container">

    <div class="news-title">
      <h2 [innerHTML]="data.title"></h2>
      <p [innerHTML]="data.text" *ngIf="data.text"></p>
    </div>

    <div class="row">
      <div class="col-md-4" *ngFor="let item of curDataes; index as i;">
        <div class="news-content">
          <div class="news-image">
            <a href="javaScript:void(0);" (click)="toDetails(item.id)">
              <img class="w-100" [src]="item.imgUrl" alt="img">
            </a>
          </div>
          <div class="news-info">
            <p [innerHTML]="item.heading"></p>
            <div class="news-detail">
              <span [innerHTML]="item.timeText"></span>
              <a href="javaScript:void(0);" (click)="toDetails(item.id)" [innerHTML]="item.linkText"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pagination -->
  <div class="news-pagination d-flex justify-content-center">
    <u-pagination [(currentPageIndex)]="curPage" [pageIndexes]="pageIndexes"
                       (currentPageIndexChange)="pageChange($event)" [maxItemSize]="maxSize">
    </u-pagination>
    <span [innerHTML]="pageInfo" *ngIf="!isWebOrMobile()"></span>
  </div>

</div>
