import { Component } from '@angular/core';
import {BaseAppComponent} from '@shared/lib/components/base-app/base-app.component';


@Component({
  selector: 'u-app-root',
  templateUrl: './baoying-app.component.html',
  styleUrls: ['./baoying-app.component.scss'],
})


export class BaoyingAppComponent extends BaseAppComponent {

}
