<div class="container">
  <div class="page-index" (click)="onClickPrevious()" [class.disabled]="currentPageIndex === start">&laquo;
  </div>
  <ng-container *ngIf="pageIndexes.length < maxItemSize * 2; then noEllipses; else hasEllipses"></ng-container>
  <ng-template #noEllipses>
    <div class="page-index"
         *ngFor="let pageIndex of pageIndexes"
         [class.active]="currentPageIndex === pageIndex"
         (click)="onClickPageIndex(pageIndex)">
      {{pageIndex}}
    </div>
  </ng-template>
  <ng-template #hasEllipses>
    <ng-container *ngIf="currentPageIndex > 0 && currentPageIndex <= maxItemSize">
      <div class="page-index"
           *ngFor="let pageIndex of pageIndexes.slice(0, maxItemSize)"
           [class.active]="currentPageIndex === pageIndex"
           (click)="onClickPageIndex(pageIndex)">
        {{pageIndex}}
      </div>
      <div class="page-index disabled">...</div>
      <div class="page-index" (click)="onClickPageIndex(end)">{{end}}</div>
    </ng-container>
    <ng-container *ngIf="currentPageIndex > (end - maxItemSize) && currentPageIndex <= end">
      <div class="page-index" (click)="onClickPageIndex(start)">{{start}}</div>
      <div class="page-index disabled">...</div>
      <div class="page-index"
           *ngFor="let pageIndex of pageIndexes.slice(pageIndexes.length - maxItemSize, pageIndexes.length)"
           [class.active]="currentPageIndex === pageIndex"
           (click)="onClickPageIndex(pageIndex)">
        {{pageIndex}}
      </div>
    </ng-container>
    <ng-container *ngIf="currentPageIndex > maxItemSize && currentPageIndex <= (end - maxItemSize)">
      <div class="page-index" (click)="onClickPageIndex(start)">{{start}}</div>
      <div class="page-index disabled">...</div>
      <div class="page-index"
           *ngFor="let pageIndex of pageIndexes.slice(currentPageIndex - centralIndexesSize, currentPageIndex + centralIndexesSize)"
           [class.active]="currentPageIndex === pageIndex"
           (click)="onClickPageIndex(pageIndex)">
        {{pageIndex}}
      </div>
      <div class="page-index disabled">...</div>
      <div class="page-index" (click)="onClickPageIndex(end)">{{end}}</div>
    </ng-container>
  </ng-template>
  <div class="page-index" (click)="onClickNext()"
       [class.disabled]="currentPageIndex === end">&raquo;
  </div>
</div>
