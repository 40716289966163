import {Locale} from '@shared/lib/model/locale';
import {DotcomMarket} from '@shared/lib/model/dotcom-market';

export const Locales: {[localeKey: string]: Locale} = {
  'zhs-CN': {
    key: 'zhs-CN',
    warehouse: 'BJD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: false
  }
};

export const Markets: DotcomMarket[] = [
  {
    name: 'China', flag: 'cn', languages: [
      {
        name: 'BJD',
        locale: 'zhs-CN',
        language: '簡體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ]
  }
];
