import {Component, ViewEncapsulation} from '@angular/core';
import {PageComponent} from '@shared/lib/components/page.component';
import {Params} from '@angular/router';

@Component({
  selector: 'u-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  encapsulation: ViewEncapsulation.None // eslint-disable-line
})
export class NewsListComponent extends PageComponent {

  syncLoadcontent = true;
  amountNumSrc;
  src;

  totalDataes;
  map;
  pageInfo;
  navData;
  param;

  mapData = {
    totalNum: 'totalNum',
    navText1: 'navText1',
    navText2: 'navText2',
    pageText1: 'pageText1',
    pageText2: 'pageText2',
  };

  // the number of Dataes
  totalDataNum;
  // the number of each page data
  pageSize: number;
  // the maxSize of each page
  maxSize: number;
  // the size of current page
  curPage = 1;
  // the Dataes of the current page
  curDataes;
  // the index that clicked tab
  dataIndex: number;
  // the total pages
  totalPages: number;

  pageIndexes: number[] = [];

  private unusedIndexes: number[];

  private readonly unusedNewsIndexes: number[] = [39, 21, 17, 14, 19, 48];
  private readonly unusedCharityIndexes: number[] = [];
  private readonly unusedCharityNewsIndexes: number[] = [];

  // the final data and pageNum on curPage
  pageChange(pageNum: number) {
    this.curPage = pageNum;
    this.curDataes = this.getPageData(pageNum);
    window.scrollTo(0, 0);
  }

  // the Web or the mobile
  isWebOrMobile(): boolean {
    const screenWidth = document.body.clientWidth;
    return screenWidth <= 750;
  }

  // get the data number of curpage
  getPageData(page: number) {
    if (!this.isWebOrMobile()) {
      this.pageSize = 18;
      this.maxSize = 5;
    } else {
      this.pageSize = 9;
      this.maxSize = 3;
    }
    this.totalPages = Math.floor((this.totalDataNum - 1) / this.pageSize) + 1;
    this.pageIndexes = [];
    for (let i = 1; i <= this.totalPages; i++) {
      this.pageIndexes.push(i);
    }

    this.pageInfo = this.navData.pageText1 + this.totalPages + this.navData.pageText2;

    // get the data
    const dataes: any = [];
    const _dataes = this.totalDataes.items;
    if (_dataes === undefined) {
      return;
    }

    for (let i = (page - 1) * this.pageSize; i < page * this.pageSize; i++) {
      dataes.push(_dataes[i]);
      if (_dataes[i] === undefined) {
        dataes.pop(_dataes[i]);
      }
    }
    return dataes;
  }

  toDetails(page: number): void {
    this.getDataIndex(page);
    this.router.navigate(['/zhs-CN/details', this.param, this.dataIndex]);
  }

  getDataIndex(curIndex: number): number {
    this.dataIndex = curIndex;
    return this.dataIndex;
  }

  // get the items options
  private getItems(itemNum: number) {
    const items = [];
    for (let i = 0; i < itemNum; i++) {
      if (!this.unusedIndexes.includes(i)) {
        items.push({
          id: 'items.' + i + '.id',
          imgUrl: 'items.' + i + '.imgUrl',
          heading: 'items.' + i + '.heading',
          timeText: 'items.' + i + '.timeText',
          linkText: 'items.' + i + '.linkText',
        });
      }
    }
    return items;
  }

  processComponentData(data: any) {
    this.totalDataes = data;
    this.curDataes = this.getPageData(this.curPage);
  }

  protected defaultComponentData() {
    this.dataIsLoaded = false;
  }

  localeChangeBeforeLoadContent(data): void {
    this.getFinalSrc();
    this.translationMapperService.translateFromMap(this.amountNumSrc, this.mapData).then(tData => {
      this.navData = tData;
      this.totalDataNum = tData.totalNum - this.unusedIndexes.length;

      this.map = {
        title: 'title',
        text: 'text',
        items: this.getItems(tData.totalNum).reverse(),
      };
      this.loadContent();
    });
  }

  getFinalSrc() {
    this.route.params.subscribe((params) => {
      this.setInitialData(params);
    });
  }

  // eslint-disable-next-line
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.setInitialData(params);
      super.ngOnInit();
      this.curPage = 1;
    });
  }

  setInitialData(params: Params): void {
    this.param = params.newslist;
    if (this.param === 'press-release') {
      this.amountNumSrc = 'page-news-list-amount';
      this.src = 'page-news-list';
      this.unusedIndexes = this.unusedNewsIndexes;
    } else if (this.param === 'charity-group') {
      this.amountNumSrc = 'page-charity-list-amount';
      this.src = 'page-charity-list';
      this.unusedIndexes = this.unusedCharityIndexes;
    } else if (this.param === 'charity-news') {
      this.amountNumSrc = 'page-charity-news-amount';
      this.src = 'page-charity-news';
      this.unusedIndexes = this.unusedCharityNewsIndexes;
    } else {
      this.router.navigate(['/zhs-CN/falsepath']);
    }
  }

}
