import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAndroid, faWeibo, faWeixin } from '@fortawesome/free-brands-svg-icons';

const BAOYING_FONTAWESOME_ICONS = [faWeixin, faWeibo, faAndroid];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class BaoyingFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...BAOYING_FONTAWESOME_ICONS);
  }
}
