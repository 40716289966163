const dep = {
  siteDomain: '[___ key "global/org_baoying_deploy_SiteDomain" ___]',
};

export const environment = {
  production: true,
  host: '../..',
  cartService: `https://esb.${dep.siteDomain}/cart`,
  i18nService: `https://api.${dep.siteDomain}/i18n`,
  siteDomain: dep.siteDomain,
  loginRealm: 'baoying',
  ssoEnabled: false,
};
