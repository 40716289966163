import {NgModule} from '@angular/core';
import {RouterModule, Route, NoPreloading} from '@angular/router';
import {LocaleService} from '@shared/lib/services/locale.service';
import {combineAppAndSharedRoutes} from '@shared/lib/shared.routes';
import {PageNotFoundComponent} from '@shared/lib/modules/page-not-found/components/page-not-found.component';
import {NewsListComponent} from './components/news-list/news-list.component';


const BAOYING_ROUTES: Route[] = [
  {
    path: 'home',
    loadChildren: () => import('./lazy-modules/home/baoying-home.module').then(m => m.BaoyingHomeModule)
  },
  {
    path: 'lifestyle',
    loadChildren: () => import('./lazy-modules/lifestyle/lifestyle-cn.module').then(m => m.LifestyleCnModule)
  },
  {
    path: 'pr1',
    loadChildren: () => import('./lazy-modules/pr1/pr1.module').then(m => m.Pr1Module)
  },
  {
    path: 'privacy-20200803',
    loadChildren: () => import('./lazy-modules/privacy/privacy-cn.module').then(m => m.PrivacyCnModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./lazy-modules/privacy-policy/privacy-policy-cn.module').then(m => m.PrivacyPolicyCnModule)
  },
  {
    path: 'give-back',
    loadChildren: () => import('./lazy-modules/give-back/give-back-cn.module').then(m => m.GiveBackCnModule)
  },
  {
    path: 'science',
    loadChildren: () => import('./lazy-modules/science/science-cn.module').then(m => m.ScienceCnModule)
  },
  {
    path: 'today-baoying',
    loadChildren: () => import('./lazy-modules/today/today-baoying.module').then(m => m.TodayBaoyingModule)
  },
  {
    path: 'contactcn',
    loadChildren: () => import('./lazy-modules/contact/contact-cn.module').then(m => m.ContactCnModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./lazy-modules/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./lazy-modules/details/details.module').then(m => m.DetailsModule)
  },
  {
    path: 'falsepath',
    component: PageNotFoundComponent
  },
  // there is no good way to lazy load this since it is based on the root path
  {
    path: 'direct',
    loadChildren: () => import('./lazy-modules/direct/direct.module').then(m => m.DirectModule)
  },
  // 合规专栏
  {
    path: 'compliance-column',
    loadChildren: () => import('./lazy-modules/compliance-column/compliance-column.module').then(m => m.ComplianceColumnModule)
  },
  // certification
  {
    path: 'certification',
    loadChildren: () => import('./lazy-modules/certification/certification.module').then(m => m.CertificationModule)
  }
];

const NEWS_ROUTE: Route[] = [
  {
    path: ':newslist',
    component: NewsListComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(combineAppAndSharedRoutes(BAOYING_ROUTES, NEWS_ROUTE), {
    preloadingStrategy: NoPreloading
}),
  ],
  exports: [RouterModule],
  providers: [LocaleService]
})
export class BaoyingRoutingModule {
}






