<header class="fixed-top">
  <u-sticky-banner src="sticky-banner" class="{{showhide}}"></u-sticky-banner>
  <a (click)="skipToMain()" href onclick="return false;" class="a11y-link">Skip to Content</a>
  <u-navbar src="menu"></u-navbar>
</header>
<main #main id="main" tabindex="-1" class="main-{{showhide}}">
  <router-outlet></router-outlet>
<!--  <u-sso-admin-toolbox></u-sso-admin-toolbox>-->
</main>
<footer>
  <u-footer-cn src="footer-cn"></u-footer-cn>
  <div id="optanon-cookie-policy" style="display: none;"></div>
</footer>
<!-- online consultation -->
<u-online-consultation-cn></u-online-consultation-cn>
