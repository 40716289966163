import {Component} from '@angular/core';
import {BaseComponent} from '@shared/lib/components/base.component';

@Component({
  selector: 'u-online-consultation-cn',
  templateUrl: './online-consultation.component.html',
  styleUrls: ['./online-consultation.component.scss']
})
export class OnlineConsultationComponent extends BaseComponent {
  src = 'online-consultation';
  map = {
    title: 'title',
    imageUrl: 'imageUrl',
    consultationUrl: 'consultationUrl'
  };
}
